import getJWT from './utils/getJWT';
window.addEventListener('load', function() {
  var isPublicFormLink, splitPath;
  splitPath = window.location.pathname.split('/');
  isPublicFormLink = splitPath.length > 4 && splitPath[2] === 'forms' && splitPath[3] === 'public';
  if (isPublicFormLink) {
    return;
  }
  var s = document.createElement('script');
  s.id = 'ze-snippet';
  s.src =
    'https://static.zdassets.com/ekr/snippet.js?key=af834e93-cd13-4d78-9b5c-9315585475e1';
  s.async = true;
  document.head.appendChild(s);
  var s2 = document.createElement('script');
  s2.type = 'text/javascript';
  s2.language = 'javasscript';
  s2.text =
    "window.zESettings = {webWidget: {helpCenter: {filter: {category: '360001964153'}}}};";
  s2.defer = true;
  document.head.appendChild(s2);

  s.onload = () => {
    setTimeout(() => {
      let decoded;
      if (window.zendeskSuggestion) {
        window.zE('webWidget', 'helpCenter:setSuggestions', {
          search: window.zendeskSuggestion
        });
      }
      decoded = getJWT();
      if (!decoded || !decoded.privileges.includes('user:read')) {
        window.zE('webWidget', 'hide');
      }
    }, 1);
  };
});
