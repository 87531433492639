const getCredasOptions = () => {
  return {
    countries: [
      'Afghanistan',
      'Åland Islands',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bonaire, Sint Eustatius and Saba',
      'Bosnia-Herzegovina',
      'Botswana',
      'Brazil',
      'British Indian Ocean Territory',
      'British Virgin Islands',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cape Verde',
      'Cayman Islands',
      'Central African Rep',
      'Chad',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Colombia',
      'Comoros',
      'Congo (Brazzaville)',
      'Congo, the Democratic Republic',
      'Cook Islands',
      'Costa Rica',
      `Cote D'Ivoire`,
      'Croatia',
      'Cuba',
      'Curacao',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica ',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Eswatini',
      'Ethiopia',
      'Falkland Islands (Malvinas)',
      'Faroe islands',
      'Fiji',
      'Finland',
      'France',
      'French Guiana',
      'French Polynesia',
      'Gabon',
      'Gambia',
      'Gaza Strip',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guernsey ',
      'Guinea',
      'Guinea Bissau',
      'Guyana',
      'Haiti',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran, Islamic Republic of',
      'Iraq',
      'Ireland',
      'Isle Of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey ',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Kosovo',
      'Kuwait',
      'Kyrgyzstan',
      `Lao People's Democratic Republic`,
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Micronesia',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'North Korea',
      'North Macedonia',
      'North Mariana Islands',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Pitcairn',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar ',
      'Réunion',
      'Romania',
      'Russian Federation',
      'Rwanda',
      'Saint Barthélemy',
      'Saint Helena, Ascension and Tristan',
      'Saint Martin (French part)',
      'Saint Pierre and Miquelon',
      'Samoa',
      'San Marino',
      'Sao Tome & Prin.',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Korea ',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'St Kitts & Nevis',
      'St Lucia',
      'St Maarten',
      'St Vincent & Gren',
      'Sudan',
      'Suriname',
      'Svalbard and Mayen',
      'Sweden',
      'Switzerland',
      'Syria',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad & Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks & Caicos',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'United States',
      'United States Virgin Islands',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Vatican City State (Holy See)',
      'Venezuela',
      'Vietnam',
      'Wallis and Futuna',
      'West Bank (Palestinian Territory, Occupied)',
      'Western Sahara',
      'Yemen',
      'Zambia',
      'Zimbabwe'
    ]
  };
};

export default getCredasOptions;
